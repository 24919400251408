.modal-open {
  overflow: hidden;
}
.modal {
  overflow: hidden;
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1050;
  -webkit-overflow-scrolling: touch;
  outline: 0;
}
.modal .modal-dialog .modal-content {
  background-color: var(--ui-gray-darker);
  border-color: var(--ui-border-color);
  color: var(--text-secondary-color);
  border: 0;
}
.modal .modal-dialog .modal-content .modal-header,
.modal .modal-dialog .modal-content .modal-footer {
  border-color: var(--ui-border-color);
}
.modal .modal-dialog .modal-content .dialog-separator,
.modal .modal-dialog .modal-content .dialog-separator-before,
.modal .modal-dialog .modal-content .dialog-separator-after {
  position: relative;
  height: 40px;
}
.modal .modal-dialog .modal-content .dialog-separator:before,
.modal .modal-dialog .modal-content .dialog-separator:after,
.modal .modal-dialog .modal-content .dialog-separator-before:before,
.modal .modal-dialog .modal-content .dialog-separator-after:after {
  background-color: #000;
  content: ' ';
  display: block;
  height: 3px;
  left: 0;
  position: absolute;
  width: 100%;
}
.modal .modal-dialog .modal-content .dialog-separator:before,
.modal .modal-dialog .modal-content .dialog-separator-before:before {
  top: -3px;
}
.modal .modal-dialog .modal-content .dialog-separator:after,
.modal .modal-dialog .modal-content .dialog-separator-after:after {
  bottom: -3px;
}
.modal .modal-dialog .modal-content .modal-body {
  color: var(--text-primary-color);
  padding: 16px 22px 0px;
  position: relative;
}
.modal .modal-dialog .modal-content .modal-body ::-webkit-scrollbar {
  width: 6px;
  background-color: var(--ui-gray-dark);
}
.modal .modal-dialog .modal-content .modal-body ::-webkit-scrollbar-thumb {
  background-color: var(--ui-gray-light);
  border-radius: 5px;
}
.modal .modal-dialog .modal-content .modal-header {
  border-bottom-width: 3px;
  border-bottom-style: solid;
  border-bottom-color: #000;
  padding: 19px 22px 17px;
  position: relative;
}
.modal .modal-dialog .modal-content .modal-header h4 {
  color: var(--text-secondary-color);
  font-size: 20px;
  font-weight: 500;
  line-height: 24px;
  padding-right: 24px;
}
.modal .modal-dialog .modal-content .modal-footer {
  border-top: 0;
  padding-top: 0;
}
.modal .modal-dialog .card-round {
  background-color: var(--ui-gray-dark);
  padding: 10px;
}
.modal .modal-dialog .modal-header {
  position: relative;
}
.modal.fade .modal-dialog {
  -webkit-transition: transform 0.3s ease-out;
  -o-transition: transform 0.3s ease-out;
  transition: transform 0.3s ease-out;
  -webkit-transform: translate(0, -25%);
  -ms-transform: translate(0, -25%);
  -o-transform: translate(0, -25%);
  transform: translate(0, -25%);
}
.modal.in .modal-dialog {
  transform: translate(0, 0);
}
.modal-open .modal {
  overflow-x: hidden;
  overflow-y: auto;
}
.modal-dialog {
  position: relative;
  width: auto;
  margin: 10px;
}
.modal-content {
  position: relative;
  background-color: #fff;
  border: 1px solid rgba(0,0,0,0.2);
  border-radius: 6px;
  -webkit-box-shadow: 0 3px 9px rgba(0,0,0,0.5);
  box-shadow: 0 3px 9px rgba(0,0,0,0.5);
  background-clip: padding-box;
  outline: 0;
}
.modal-backdrop {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1050;
  background-color: #fff;
}
.modal-backdrop.fade {
  opacity: 0;
}
.modal-backdrop.in {
  opacity: 0;
}
.modal-header {
  padding: 15px;
  border-bottom: 1px solid #e5e5e5;
}
.modal-header .close {
  margin-top: -2px;
}
.modal-title {
  margin: 0;
  line-height: 1.42857143;
}
.modal-body {
  position: relative;
  padding: 15px;
}
.modal-footer {
  padding: 15px;
  text-align: right;
  border-top: 1px solid #e5e5e5;
}
.modal-footer .btn+.btn {
  margin-left: 5px;
  margin-bottom: 0;
}
.modal-footer .btn-group .btn+.btn {
  margin-left: -1px;
}
.modal-footer .btn-block+.btn-block {
  margin-left: 0;
}
.modal-scrollbar-measure {
  position: absolute;
  top: -9999px;
  width: 50px;
  height: 50px;
  overflow: scroll;
}
@media (min-width: 768px) {
  .modal-dialog {
    width: 600px;
    margin: 30px auto;
  }
  .modal-content {
    -webkit-box-shadow: 0 5px 15px rgba(0,0,0,0.5);
    box-shadow: 0 5px 15px rgba(0,0,0,0.5);
  }
}
@media (min-width: 992px) {
  .modal-lg {
    width: 900px;
  }
}
.button-close,
.modal-dialog button.close {
  color: var(--text-secondary-color);
  height: 25px;
  opacity: 1;
  overflow: hidden;
  text-align: center;
  text-shadow: none;
  width: 25px;
  font-size: 25px;
  font-weight: lighter;
}
