.CalendarDay__selected_span {
  background: var(--calendar-main-color);
  color: var(--calendar-day-color);
  border: 1px solid;
  border-color: var(--calendar-day-border-color);
}
.CalendarDay__default:hover {
  color: var(--calendar-main-color);
}
.CalendarDay__selected_span:active,
.CalendarDay__selected_span:hover {
  background: var(--calendar-day-active-hover-background-color);
  border-color: var(--calendar-day-border-color);
  color: var(--calendar-day-color);
}
.CalendarDay__selected {
  background: var(--calendar-main-color);
  color: var(--calendar-day-color);
  border-color: var(--calendar-day-border-color);
}
.CalendarDay__selected:hover {
  background: var(--calendar-main-color);
  color: var(--calendar-day-color);
  border-color: var(--calendar-day-border-color);
}
.CalendarDay__hovered_span:hover,
.CalendarDay__hovered_span {
  background: var(--calendar-main-color);
  color: var(--calendar-day-color);
  border-color: var(--calendar-day-color);
}
.DateInput {
  width: 97px;
}
.DateInput_input {
  background-color: var(--input-background-color);
  border-color: var(--input-background-color);
  color: var(--input-placeholder-color);
  height: 38px;
  font-size: 10pt;
}
.DateRangePickerInput {
  background-color: var(--input-background-color);
  border-color: var(--input-background-color);
  color: var(--input-placeholder-color);
  height: 40px;
}
.PresetDateRangePicker_panel: {
  padding: 0 22px 11px 22px;
}
.PresetDateRangePicker_button {
  position: relative;
  height: 100%;
  textAlign: center;
  background: none;
  border: 2px solid var(--calendar-main-color);
  color: var(--calendar-main-color);
  padding: 4px 12px;
  marginRight: 8;
  font: inherit;
  fontWeight: 700;
  lineHeight: normal;
  overflow: visible;
  boxSizing: border-box;
  cursor: pointer;
}
.PresetDateRangePicker_button :active: {
  outline: 0;
}
.PresetDateRangePicker_button__selected: {
  color: var(--calendar-day-color);
  background: var(--calendar-main-color);
}
