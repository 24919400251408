.StudyBrowser {
  height: var(--study-bowser-height);
  width: 100%;
  background-color: #000;
  padding-top: 5px;
  position: relative;
}
.StudyBrowser .pre-page {
  color: #fff;
  text-align: center;
  line-height: 80px;
  position: absolute;
  left: 0;
  background-color: #666;
  width: 20px;
  height: 100%;
  z-index: 10;
}
.StudyBrowser .pre-page .pre-icon {
  transform: rotateZ(180deg);
}
.StudyBrowser .next-page {
  color: #fff;
  text-align: center;
  line-height: 80px;
  position: absolute;
  right: 0px;
  top: 5px;
  background-color: #666;
  width: 20px;
  height: 100%;
  z-index: 10;
}
.StudyBrowser:before {
  content: '请拖动序列到胶片查看区';
  color: #fff;
  position: absolute;
  bottom: -20px;
  left: 0;
  width: 100%;
  text-align: center;
  z-index: 10;
}
.StudyBrowser .scrollable-study-thumbnails {
  height: 100%;
  padding-right: 16px;
  padding-left: 4px;
  margin-right: -16px;
  display: flex;
  position: relative;
  padding-left: 20px;
  transition: all 0.5s linear 0s;
}
.StudyBrowser .scrollable-study-thumbnails::-webkit-scrollbar {
  display: none;
}
.StudyBrowser .scrollable-study-thumbnails .ThumbnailEntryContainer {
  max-width: var(--image-thumbnail-width);
  max-height: var(--image-thumbnail-height);
}
.StudyBrowser .scrollable-study-thumbnails .ThumbnailEntryContainer .noselect {
  -moz-user-select: none; /* Firefox */
  -ms-user-select: none; /* Internet Explorer */
  -khtml-user-select: none; /* KHTML browsers (e.g. Konqueror) */
  -webkit-user-select: none; /* Chrome, Safari, and Opera */
  -webkit-touch-callout: none; /* Disable Android and iOS callouts*/
}
.draggable {
  cursor: copy;
  cursor: -webkit-grab;
  cursor: -moz-grab;
}
