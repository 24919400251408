.form-themed table {
  color: var(--text-primary-color);
}
.form-themed table th,
.form-themed table td {
  font-size: 15px;
  font-weight: normal;
}
.form-themed table th {
  line-height: 60px;
}
.form-themed table td {
  line-height: 18px;
  padding: 0;
}
.form-themed .p-r-1 {
  padding-right: 1rem !important;
}
.form-themed label {
  margin-bottom: 5px;
}
.form-themed label.wrapperLabel {
  cursor: pointer;
}
.form-themed label.wrapperLabel:not(.checkboxLabel) {
  cursor: pointer;
  display: flex;
  flex-direction: column;
}
.form-themed label.wrapperLabel:not(.checkboxLabel) .wrapperText {
  display: block;
  order: -1;
}
.form-themed .btn[disabled],
.form-themed input[type=text][disabled],
.form-themed input[type=password][disabled],
.form-themed input[type=number][disabled],
.form-themed .btn.disabled,
.form-themed input[type=text].disabled,
.form-themed input[type=password].disabled,
.form-themed input[type=number].disabled,
.form-themed .btn[disabled]:hover,
.form-themed input[type=text][disabled]:hover,
.form-themed input[type=password][disabled]:hover,
.form-themed input[type=number][disabled]:hover,
.form-themed .btn.disabled:hover,
.form-themed input[type=text].disabled:hover,
.form-themed input[type=password].disabled:hover,
.form-themed input[type=number].disabled:hover,
.form-themed .btn[disabled]:active,
.form-themed input[type=text][disabled]:active,
.form-themed input[type=password][disabled]:active,
.form-themed input[type=number][disabled]:active,
.form-themed .btn.disabled:active,
.form-themed input[type=text].disabled:active,
.form-themed input[type=password].disabled:active,
.form-themed input[type=number].disabled:active {
  background-color: var(--ui-gray-darker);
  border-color: var(--ui-gray-light);
  color: var(--text-primary-color);
}
.form-themed .btn[disabled] + .wrapperText,
.form-themed input[type=text][disabled] + .wrapperText,
.form-themed input[type=password][disabled] + .wrapperText,
.form-themed input[type=number][disabled] + .wrapperText,
.form-themed .btn.disabled + .wrapperText,
.form-themed input[type=text].disabled + .wrapperText,
.form-themed input[type=password].disabled + .wrapperText,
.form-themed input[type=number].disabled + .wrapperText,
.form-themed .btn[disabled]:hover + .wrapperText,
.form-themed input[type=text][disabled]:hover + .wrapperText,
.form-themed input[type=password][disabled]:hover + .wrapperText,
.form-themed input[type=number][disabled]:hover + .wrapperText,
.form-themed .btn.disabled:hover + .wrapperText,
.form-themed input[type=text].disabled:hover + .wrapperText,
.form-themed input[type=password].disabled:hover + .wrapperText,
.form-themed input[type=number].disabled:hover + .wrapperText,
.form-themed .btn[disabled]:active + .wrapperText,
.form-themed input[type=text][disabled]:active + .wrapperText,
.form-themed input[type=password][disabled]:active + .wrapperText,
.form-themed input[type=number][disabled]:active + .wrapperText,
.form-themed .btn.disabled:active + .wrapperText,
.form-themed input[type=text].disabled:active + .wrapperText,
.form-themed input[type=password].disabled:active + .wrapperText,
.form-themed input[type=number].disabled:active + .wrapperText {
  color: var(--text-primary-color);
  cursor: auto;
}
.form-themed input[type=text],
.form-themed input[type=password],
.form-themed input[type=number] {
  background-color: var(--ui-gray);
  border-color: var(--ui-border-color);
  color: var(--text-primary-color);
  font-weight: normal;
}
.form-themed input[type=text]:active,
.form-themed input[type=password]:active,
.form-themed input[type=number]:active,
.form-themed input[type=text]:focus,
.form-themed input[type=password]:focus,
.form-themed input[type=number]:focus {
  background-color: var(--ui-gray-dark);
  border: 2px solid var(--active-color);
  box-shadow: none;
}
.form-themed input[type=text]:active + .wrapperText,
.form-themed input[type=password]:active + .wrapperText,
.form-themed input[type=number]:active + .wrapperText,
.form-themed input[type=text]:focus + .wrapperText,
.form-themed input[type=password]:focus + .wrapperText,
.form-themed input[type=number]:focus + .wrapperText {
  color: var(--active-color);
}
.form-themed .btn-primary {
  background-color: var(--active-color);
  border-collapse: var(--ui-border-color-active);
  color: var(--ui-text-color-active);
}
.form-themed .btn-primary:hover,
.form-themed .btn-primary:active,
.form-themed .btn-primary:focus,
.form-themed .btn-primary:focus:active {
  background-color: var(--ui-active-color);
  border-collapse: var(--ui-border-color-active);
  color: var(--ui-text-color-active);
}
.form-themed .form-control {
  background-color: #fff;
  border: 0;
  font-size: 14px;
  height: 30px;
  line-height: 16px;
  padding: 8px 9px 6px;
}
.form-control {
  display: block;
  width: 100%;
  height: 34px;
  padding: 6px 12px;
  font-size: 14px;
  line-height: 1.42857143;
  color: #555;
  background-color: #fff;
  background-image: none;
  border: 1px solid #ccc;
  border-radius: 4px;
  box-shadow: inset 0 1px 1px rgba(0,0,0,0.075);
}
.form-control:focus {
  border-color: #66afe9;
  outline: 0;
  -webkit-box-shadow: inset 0 1px 1px rgba(0,0,0,0.075), 0 0 8px rgba(102,175,233,0.6);
  box-shadow: inset 0 1px 1px rgba(0,0,0,0.075), 0 0 8px rgba(102,175,233,0.6);
}
.form-control::-ms-expand {
  border: 0;
  background-color: transparent;
}
.form-control[disabled],
.form-control[readonly],
fieldset[disabled] .form-control {
  background-color: ;
  opacity: 1;
}
.form-control[disabled],
fieldset[disabled] .form-control {
  cursor: ;
}
input[type=range] {
  display: block;
  width: 100%;
}
* {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}
.full-width {
  width: 100%;
}
.full-height {
  height: 100%;
}
.flex-h {
  display: flex;
  flex-direction: row;
}
.flex-v {
  display: flex;
  flex-direction: column;
}
.flex-grow {
  flex-grow: 1;
}
.nowrap {
  white-space: nowrap;
}
.text-right {
  text-align: right;
}
.text-center {
  text-align: center;
}
.text-left {
  text-align: left;
}
h1 {
  font-size: 36px;
}
h3,
h1 {
  font-family: inherit;
  font-weight: 500;
  line-height: 1.1;
  margin-top: 20px;
  margin-bottom: 10px;
}
pre {
  display: block;
  padding: 9.5px;
  margin: 0 0 10px;
  font-size: 13px;
  line-height: 1.42857143;
  color: #333;
  word-break: break-all;
  word-wrap: break-word;
  background-color: #f5f5f5;
  border: 1px solid #ccc;
  border-radius: 4px;
}
button,
input,
select,
textarea {
  font-family: inherit;
  font-size: inherit;
  line-height: inherit;
}
.row {
  margin-right: -15px;
  margin-left: -15px;
}
.pull-left {
  float: left !important;
}
.pull-right {
  float: right !important;
}
.fade {
  opacity: 0;
  -webkit-transition: opacity 0.15s linear;
  -o-transition: opacity 0.15s linear;
  transition: opacity 0.15s linear;
}
.fade.in {
  opacity: 1;
}
a {
  color: #337ab7;
  text-decoration: none;
}
.DateRangePicker {
  height: 40px;
  margin: 0 5px 20px 5px;
  cursor: pointer;
  border: none;
  position: block;
}
.studyListToolbar {
  background-color: var(--ui-gray-darker);
  height: 75px;
  margin-bottom: 2px;
  padding: 0 8%;
}
.studyListToolbar>div {
  display: inline-block;
}
.studyListToolbar .header {
  font-size: 22px;
  font-weight: 300;
  color: var(--table-text-secondary-color);
  line-height: 75px;
}
.studyListToolbar .studylistToolbar {
  height: 75px;
  line-height: 75px;
}
.studyListToolbar .studyCount {
  color: var(--large-numbers-color);
  font-size: 40px;
  font-weight: 100;
  line-height: 75px;
}
.theadBackground {
  height: 121px;
  position: absolute;
  width: 100%;
}
.theadBackground:before,
.theadBackground:after {
  content: '';
  display: block;
  left: 0;
  position: absolute;
  width: 100%;
}
.theadBackground:before {
  background-color: var(--ui-gray-darker);
  height: 100%;
  top: 0;
  z-index: 1;
}
.theadBackground:after {
  background-color: var(--ui-border-color-active);
  bottom: -1px;
  height: 1px;
  z-index: 2;
}
#studyListContainer {
  width: 100%;
  padding: 0 8%;
  position: absolute;
  z-index: 2;
}
#studyListContainer .loading-text {
  color: var(--table-text-secondary-color);
  font-size: 30px;
}
#studyListContainer .notFound {
  color: var(--table-text-secondary-color);
  font-size: 30px;
  font-weight: 200;
  text-align: center;
}
#studyListContainer table#tblStudyList {
  width: 100%;
  max-width: 100%;
  margin-bottom: 20px;
  border-spacing: 0;
  border-collapse: collapse;
}
#studyListContainer table#tblStudyList > tr {
  height: 20px;
}
#studyListContainer table#tblStudyList > thead {
  white-space: nowrap;
}
#studyListContainer table#tblStudyList > thead tr th {
  padding: 0;
  border-bottom: 1px solid var(--ui-border-color-active);
  width: 100%;
  text-align: left;
  border-top: 0;
}
#studyListContainer table#tblStudyList > thead tr th.studyDate {
  min-width: 230px;
}
#studyListContainer table#tblStudyList > thead tr th .display-text {
  display: inline-block;
  cursor: pointer;
  width: 100%;
  min-width: 95px;
  margin: 0 auto;
  color: var(--table-text-primary-color);
  font-weight: 400;
  padding: 20px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}
#studyListContainer table#tblStudyList > thead tr th .display-text span {
  font-size: 15px;
  float: left;
}
#studyListContainer table#tblStudyList > thead tr th .display-text i {
  margin: 0 5px;
}
#studyListContainer table#tblStudyList > thead tr th .display-text:hover {
  color: var(--active-color);
}
#studyListContainer table#tblStudyList > thead tr th .display-text.active,
#studyListContainer table#tblStudyList > thead tr th .display-text:active {
  color: var(--active-color);
}
#studyListContainer table#tblStudyList > thead tr th input.studylist-search {
  height: 40px;
  margin: 0 5px 20px 5px;
  padding: 0 20px;
  cursor: pointer;
  border: none;
  background-color: var(--input-background-color);
  color: var(--input-placeholder-color);
  font-size: 10pt;
  font-weight: normal;
  width: calc(100% - 10px);
  border-radius: 4px;
}
#studyListContainer table#tblStudyList > thead tr th input.studylist-search::-webkit-input-placeholder {
  color: var(--input-placeholder-color);
}
#studyListContainer table#tblStudyList > thead tr th input.studylist-search:-moz-placeholder {
  color: var(--input-placeholder-color);
}
#studyListContainer table#tblStudyList > thead tr th input.studylist-search::-moz-placeholder {
  color: var(--input-placeholder-color);
}
#studyListContainer table#tblStudyList > thead tr th input.studylist-search:-ms-input-placeholder {
  color: var(--input-placeholder-color);
}
#studyListContainer table#tblStudyList > thead tr th input.studylist-search.invisible {
  visibility: hidden;
}
#studyListContainer table#tblStudyList > thead tr th input.studylist-search:active,
#studyListContainer table#tblStudyList > thead tr th input.studylist-search:hover {
  background-color: var(--input-background-color);
}
#studyListContainer table#tblStudyList > thead ::-webkit-datetime-edit-year-field:not([aria-valuenow]),
#studyListContainer table#tblStudyList > thead ::-webkit-datetime-edit-month-field:not([aria-valuenow]),
#studyListContainer table#tblStudyList > thead ::-webkit-datetime-edit-day-field:not([aria-valuenow]) {
  color: transparent;
}
#studyListContainer table#tblStudyList > tbody tr {
  padding: 5px;
  background-color: #000;
}
#studyListContainer table#tblStudyList > tbody tr:nth-child(even) {
  background-color: var(--ui-gray-darker);
}
#studyListContainer table#tblStudyList > tbody tr td {
  padding: 8px;
  height: 40px;
  line-height: 40px;
  color: var(--table-text-primary-color);
  font-weight: 300;
  border-top: 1px solid var(--ui-gray-lighter);
  border-bottom: 1px solid var(--ui-gray-lighter);
  white-space: nowrap;
}
#studyListContainer table#tblStudyList > tbody tr td.emptyCell {
  color: #516873;
}
#studyListContainer table#tblStudyList > tbody tr td.emptyValue {
  color: var(--ui-gray-light);
}
#studyListContainer table#tblStudyList > tbody tr:hover,
#studyListContainer table#tblStudyList > tbody tr:active,
#studyListContainer table#tblStudyList > tbody tr.active {
  background-color: var(--table-hover-color);
  color: var(--text-primary-color);
}
#studyListContainer table#tblStudyList > tbody tr:hover td,
#studyListContainer table#tblStudyList > tbody tr:active td,
#studyListContainer table#tblStudyList > tbody tr.active td {
  border-top: 1px solid var(--ui-gray-lighter);
  border-bottom: 1px solid var(--ui-gray-lighter);
  background-color: var(--table-hover-color);
}
@media only screen and (max-width: 1362px) {
  #studyListContainer {
    padding: 0 5%;
  }
  #studyListContainer table#tblStudyList thead tr th:first-child,
  #studyListContainer table#tblStudyList tbody tr th:first-child,
  #studyListContainer table#tblStudyList thead tr td:first-child,
  #studyListContainer table#tblStudyList tbody tr td:first-child {
    padding-left: 5%;
  }
  #studyListContainer table#tblStudyList thead tr th:last-child,
  #studyListContainer table#tblStudyList tbody tr th:last-child,
  #studyListContainer table#tblStudyList thead tr td:last-child,
  #studyListContainer table#tblStudyList tbody tr td:last-child {
    padding-right: 5%;
  }
}
@media only screen and (max-width: 1161px) {
  #studyListContainer {
    padding: 0 $tablePaddingBigWidth;
  }
  #studyListContainer table#tblStudyList thead tr th:first-child,
  #studyListContainer table#tblStudyList tbody tr th:first-child,
  #studyListContainer table#tblStudyList thead tr td:first-child,
  #studyListContainer table#tblStudyList tbody tr td:first-child {
    padding-left: 3%;
  }
  #studyListContainer table#tblStudyList thead tr th:last-child,
  #studyListContainer table#tblStudyList tbody tr th:last-child,
  #studyListContainer table#tblStudyList thead tr td:last-child,
  #studyListContainer table#tblStudyList tbody tr td:last-child {
    padding-right: 3%;
  }
}
@media only screen and (max-width: 1069px) {
  .theadBackground {
    height: 101px;
  }
  .studylist-pagination > .row {
    margin-right: 0;
  }
  .studyListToolbar {
    padding: 0 5px;
  }
  #studyListContainer {
    padding: 0;
  }
  #studyListContainer table#tblStudyList thead > tr > th:first-child {
    padding-left: 5px;
  }
  #studyListContainer table#tblStudyList thead > tr > th:last-child {
    padding-right: 5px;
  }
  #studyListContainer table#tblStudyList thead > tr > th input.worklist-search {
    padding: 10px;
  }
  #studyListContainer table#tblStudyList thead > tr > th .display-text {
    padding: 10px 5px;
  }
  #studyListContainer table#tblStudyList thead > tr > th .display-text i {
    width: auto;
  }
  #studyListContainer table#tblStudyList tbody > tr > td:first-child {
    padding-left: 5px;
  }
  #studyListContainer table#tblStudyList tbody > tr > td:last-child {
    padding-right: 5px;
  }
  #studyListContainer .worklistPagination .row {
    margin-left: 0;
    margin-right: 0;
  }
}
